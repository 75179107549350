import { useTranslation } from "react-i18next"

export const PhrasesSaved: React.FC = () => {
    const { t } = useTranslation();

    return <div className="mainContainer">  
        <h1>{t("savedHeader")}</h1>   
        <h4>{t("savedSubHeader")}</h4>
    </div>
}
