import axios, { AxiosResponse } from "axios";
import { CategoriesResponse } from "../models/categories";
import { PhrasesRequest } from "../models/phrasesRequest";

const configuration = axios.create({
    // baseURL: 'http://localhost:8080',
    baseURL: 'https://garnekmobile.com',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
})

export async function canEnterPhrases(gameId?: string, userName?: string): Promise<boolean>  {
    const response: AxiosResponse<boolean> = await configuration.get('/api/public/CanAddPhrases/' + gameId + '/' + userName);
    return response.data;
}

export async function getCategories(gameId?: string): Promise<CategoriesResponse | undefined> {
    const response: AxiosResponse<CategoriesResponse> = await configuration.get('/api/public/GetCategoriesByGameId/' + gameId);
    return response.data;
}

export async function addPhrases(phrases: PhrasesRequest): Promise<any> {
    const response = await configuration.post('/api/public/AddPhrases', JSON.stringify(phrases));
    return response;
}