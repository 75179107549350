import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { EnterPhrases } from './components/EnterPhrases/EnterPhrases';
import { ErrorPage } from './components/Error/ErrorPage';
import { PhrasesSaved } from './components/PhrasesSaved/PhrasesSaved';
import { Home } from './components/Home/Home';
import { ToastContainer } from 'react-toastify';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Support } from './components/Support/Support';

function App() {

  const userLanguage = navigator.language.split(/[-_]/)[0];
  
  i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en.json'),
      },
      pl: {
        translation: require('./locales/pl.json'),
      },
      // Add more languages as needed
    },
    lng: userLanguage, // Set the initial language based on the user's location
    fallbackLng: 'en', // Fallback language in case the selected language is not available
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" Component={Home}></Route>
        <Route path="/error" Component={ErrorPage}></Route>
        <Route path="/success" Component={PhrasesSaved}></Route>
        <Route path="/:gameId/:userId" Component={EnterPhrases}/>
        <Route path='/support' Component={Support}/>
      </Routes>
      <ToastContainer icon={true}/>
    </BrowserRouter>
  );
}

export default App;
