import { FormEventHandler } from "react";
import { EnteredPhrases } from "../../models/enteredPhrases";

export type EnterPhrasesProps = {
    userName?: string;
    categories?: string[];
    phrasesPerCategory?: number;
    buttonLabel: string;
    header: string;
    subHeader: string;
    saveButtonDisabled: boolean;
    enteredPhrases: EnteredPhrases[],
    phrasePlaceholder: string,
    onPhrasesSaved: FormEventHandler<HTMLFormElement>;
    onPhraseChanged: (category: string, phrase: string, index: number) => void;
}

export const EnterPhrasesView: React.FC<EnterPhrasesProps> = ({
    userName,
    categories,
    phrasesPerCategory,
    buttonLabel, 
    header,
    subHeader,
    saveButtonDisabled,
    phrasePlaceholder,
    enteredPhrases,
    onPhrasesSaved,
    onPhraseChanged
}) => {

    const renderInputs = () => (
        categories?.map((cat, index) => (
            <div key={'category_' + index} className="singleCategoryContainer">
                <h3>{cat}</h3>
                {
                    Array.from({ length: phrasesPerCategory ?? 0}, () => '').map((v, i) => (
                        <div key={'phrase_' + i + '_cat_' + index} className="singleCategoryContainer">
                            <input 
                                minLength={2}
                                maxLength={40}
                                placeholder={phrasePlaceholder}
                                value={enteredPhrases.find(p => p.category === cat)?.phrases[i]}
                                className="phraseInput" 
                                onChange={(e) => {
                                    onPhraseChanged(cat, e.target.value, i);
                            }}/>
                        </div>
                    ))
                }
            </div>
        ))
    )

    return (
        <div className="mainContainer">
            <h1 className="enterPhrasesHeader">{'Garnek'}</h1>
            <h4 className="subHeader">{header.replace('{userName}', userName ?? '')}</h4>
            <h4 className="subHeader">{subHeader}</h4>
            <form className="categoriesContainer" onSubmit={onPhrasesSaved}>
            {
                renderInputs()
            }
            <button className="primaryButton" disabled={saveButtonDisabled} type="submit">{buttonLabel}</button>
            </form>
        </div>
    )
}