import React from 'react';
import AppleStoreBadge from '../../assets/applestore.svg';
import GooglePlayBadge from '../../assets/googleplay.png'; // Replace with your Google Play badge
import './Home.css'; // Make sure to import or define your styles
import { useTranslation } from 'react-i18next';
import image from "../../assets/logo_transparent.png";
import { SocialIcon } from 'react-social-icons';

export const Home: React.FC = () => {
    const { t } = useTranslation();
    return (
        <div className="mainContainerHome">
            <img className='garnekIcon' src={image}/>
            <h2>{t("homeHeader")}</h2>
            {/* Description Section */}
            {/* <section className="description">
                <h2>{t('descriptionHeader')}</h2>
                <p>{t('descriptionContent')}</p>
            </section> */}
            
            {/* Game Rules Section */}
            <section className="gameRules">
                <h2>{t('gameRulesHeader')}</h2>
                <div dangerouslySetInnerHTML={{
                    __html: t('gameRulesDescription')
                }}></div>
            </section>

            <section>
            <h2>{t('download')}</h2>
            <div className="storeBadges">
                <img
                    className="badgeApple"
                    src={AppleStoreBadge}
                    alt="Download on the App Store"
                    onClick={() => window.open("https://apps.apple.com/pl/app/garnek-party-game/id6474679699?l=pl")}
                />

                {/* Android coming soon */}
                <img
                    className="badgeGoogle"
                    src={GooglePlayBadge}
                    alt="Get it on Google Play"
                />
                </div>
            </section>

            {/* Developers Info Section */}
            <section className="developersInfo">
                <h2>{t('developersHeader')}</h2>
                <SocialIcon 
                    className='socialIcon' 
                    url='https://www.facebook.com/garnek.party'/>
                    
                <SocialIcon 
                    className='socialIcon'
                    url='https://www.linkedin.com/in/bartlomiejpierog98/'/>

                <SocialIcon 
                    className='socialIcon'
                    url='https://www.linkedin.com/in/bartosz-o-aba780255/'/>

                <SocialIcon 
                    className='socialIcon'
                    url='https://github.com/pierozkitwarozki'/>
            </section>

            </div>
        );
};

export default Home;
